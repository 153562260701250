import type { Dayjs } from "dayjs";
import type { DateRange, Granularity, ValidRange } from "./types";

import dayjs from "dayjs";
import { useLayoutEffect, useState } from "react";

import IconButton from "@/components/Button/IconButton";

import usePrevProps from "@/js/hooks/usePrevProps";
import { classnames } from "@/js/utils/cambio";

import Button from "../Button";
import { Granularities } from "./constants";
import Period from "./Period";

export interface CalendarProps {
  /** A tuple denoting the limits of the pickable range. If an entry is null, then the range has no limit */
  validRange?: ValidRange;
  onSelectRange: (range: DateRange) => void;
  /**
   * This can be an empty array, a single entry (ie for date flavor or if only the start date has
   * been chosen in a range), or a start/end tuple. Each start/end can also be null when it gets
   * unset.
   */
  selectedRange: DateRange;
  /**
   * This is the granularity of what is clickable in the calendar, not the period shown. So a month
   * granularity means that months are clickable, but the period shown is a year. A day means that
   * a month is shown at a time (and the days are clickable).
   */
  granularity?: Granularity;
  /** If a date flavor, we show a single month at a time and only have one selection */
  flavor?: "date" | "range";
}

/**
 * Displays two months side-by-side with clickable dates, subject to the `validRange`. Arrows on
 * either side can navigate month by month. The selected date range is denoted by two 'bookend'
 * dates, with each date between displayed as "selected". There is another state, though, when
 * only one part of a range has been selected, other dates can be "hovered", where each date
 * between the hovered date and selected date is "highlighted."
 */
export default function Calendar({
  validRange = [null, dayjs().startOf("day")],
  selectedRange = [],
  onSelectRange,
  flavor = "range",
  granularity = "day",
}: CalendarProps) {
  const { largeInterval, smallInterval } = Granularities[granularity];

  /**
   * When only a single date is selected, every date hovered over gets set as state. We can't simply
   * rely on CSS here because the two dates could span different months.
   */
  const [hoveredDate, setHoveredDate] = useState<Dayjs | null>(null);
  /** We take the month of the latest-selected date as the right-most month to show */
  const [visiblePeriod, setVisiblePeriod] = useState<Dayjs>(
    (selectedRange[1] || selectedRange[0] || dayjs()).startOf(largeInterval),
  );
  const prevSelectedRange = usePrevProps(selectedRange);

  const periodProps = {
    hoveredDate,
    onHoverDate: setHoveredDate,
    onSelectRange,
    selectedRange,
    validRange,
  };

  /**
   * When we get a new selected range, we need to update our visiblePeriod state. Doing it this way
   * is generally ill-advised in React, instead relying on a `key` on the Calendar to just re-mount
   * it when the selectedRange changes.
   *
   * But here we don't want to simply reset everything. We actually only want to reset the state if
   * the new latest-selected date is not in view. For example, if the previous date was in March and
   * the next one in Feb, we don't want to move Feb over to the right, because that's a jarring
   * experience. Leave it on the left in that case. For this reason, this state resetting must
   * happen here.
   */
  useLayoutEffect(() => {
    /**
     * Find out which date changed. If the end date has changed, use that; if the start date
     * changed, use that. Default to the end date, if it exists. If not, use today.
     */
    const hasEndDateChanged =
      prevSelectedRange.current[1] &&
      selectedRange[1] &&
      !selectedRange[1]?.isSame(prevSelectedRange.current[1], smallInterval);
    const hasStartDateChanged =
      prevSelectedRange.current[0] &&
      selectedRange[0] &&
      !selectedRange[0].isSame(prevSelectedRange.current[0], smallInterval);

    const dateToLocate =
      hasEndDateChanged ? selectedRange[1]
      : selectedRange.filter(Boolean).length === 1 || hasStartDateChanged ?
        // this "add" is because visible period is the right-hand side, but the start period
        // we want to stay on the left. unless there is no end date, in which case it's okay
        // that it's on the right-side if that's where it was picked.
        selectedRange[0]?.add(
          flavor === "date" || selectedRange[0]?.isSame(visiblePeriod, largeInterval) ? 0 : 1,
          largeInterval,
        )
      : selectedRange[1] || dayjs();

    // only reset state if the new visible period is not in view,
    // so if not current visible period or visible period - 1
    if (
      dateToLocate &&
      (dateToLocate.startOf(largeInterval).isBefore(visiblePeriod.subtract(1, largeInterval)) ||
        dateToLocate.startOf(largeInterval).isAfter(visiblePeriod) ||
        // this takes care of an edge case not handled by the previous cases where the first date
        // is 2 periods earlier than the visiblePeriod. in that case, without this nothing would
        // change.
        (hasStartDateChanged &&
          selectedRange[0]?.isSame(visiblePeriod.subtract(2, largeInterval), largeInterval)))
    ) {
      setVisiblePeriod(dateToLocate.startOf(largeInterval));
    }
  }, [selectedRange.map((day) => day?.format("MMM DD, YYYY") || "").join()]);

  return (
    <div className={classnames("Calendar", { [flavor]: flavor !== "range" })}>
      <IconButton
        icon="arrow-back"
        onClick={(evt) => {
          evt.stopPropagation();
          setVisiblePeriod(visiblePeriod.subtract(1, largeInterval));
        }}
      />
      {flavor === "range" ?
        <Period
          granularity={granularity}
          flavor={flavor}
          period={visiblePeriod.subtract(1, largeInterval)}
          {...periodProps}
        />
      : null}
      <Period granularity={granularity} flavor={flavor} period={visiblePeriod} {...periodProps} />
      <IconButton
        icon="arrow-forward"
        onClick={(evt) => {
          evt.stopPropagation();
          setVisiblePeriod(visiblePeriod.add(1, largeInterval));
        }}
      />
      {flavor === "date" ?
        <footer>
          <Button flavor="link" onClick={() => onSelectRange([dayjs()])}>
            Today
          </Button>
        </footer>
      : null}
    </div>
  );
}

export type { DateRange, ValidRange } from "./types";
