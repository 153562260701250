import type { Dayjs } from "dayjs";
import type { DateRange, Granularity, ValidRange } from "../types";

import dayjs from "dayjs";

import { classnames } from "@/js/utils/cambio";

import { Granularities } from "../constants";

interface PeriodProps {
  hoveredDate: Dayjs | null;
  /**
   * Start date for the period (month or year). It technically doesn't even need to be the start
   * date, the component will ensure we revert the date to the start.
   */
  period: Dayjs;
  onHoverDate: (val: Dayjs) => void;
  onSelectRange: (range: DateRange) => void;
  /**
   * This can be an empty array, a single entry (ie for date flavor or if only the start date has
   * been chosen in a range), or a start/end tuple. Each start/end can also be null when it gets
   * unset.
   */
  selectedRange: DateRange;
  flavor?: "date" | "range";
  /**
   * This is the granularity of what is clickable in the calendar, not the period shown. So a month
   * granularity means that months are clickable, but the period shown is a year. A day means that
   * a month is shown at a time (and the days are clickable).
   */
  granularity: Granularity;
  /** A tuple denoting the limits of the pickable range. If an entry is null, then the range has no limit */
  validRange: ValidRange;
}

/**
 * Shows dates of a month or year in a grid. For a month, we also show previous month and following
 * month dates shown as disabled just enough to fill out the first and last weeks, respectively. For
 * a year, we show a 4 x 3 grid of months.
 *
 * Biggest logic here is for all the states that a date could have:
 *
 * * bookend - this is the start or end of a selected range
 * * bookend-left/bookend-right - start/end, respectively, of the range
 * * disabled - whether date is from a different month/year or out of the valid range
 * * hovered - can happen when selectedRange has only one entry and the user hovers on a new date
 * * highlighted - when we have a hovered date, all dates between that and the selected date are
 *   highlighted. that can be before or after the selected date.
 * * selected - when we have two dates in the selected range, a date is selected when it is between
 */
export default function Period({
  validRange,
  hoveredDate,
  period,
  onSelectRange,
  selectedRange,
  onHoverDate,
  flavor = "range",
  granularity = "day",
}: PeriodProps) {
  const { largeInterval, smallInterval } = Granularities[granularity];
  const isRangeFlavor = flavor === "range";
  const isYearType = granularity === "month";
  const startOfPeriod = period.startOf(largeInterval);
  // how far to offset the first of the month to align with the day of the week
  const gridOffset = startOfPeriod.day();
  const displayDates =
    isYearType ?
      Array.from({ length: 12 }, (el, i) => startOfPeriod.add(i, "month"))
    : Array.from({ length: Math.ceil((gridOffset + period.daysInMonth()) / 7) * 7 }, (el, i) =>
        startOfPeriod.add(i - gridOffset, "day"),
      );

  /**
   * When selectedRange is empty or has a complete date range, clicking a date starts a new range
   * (ie, the callback is called with a single date). If we have one selected date, then the new
   * date gets prepended or appended, depending on whether it is before or after, respectively.
   */
  const onSelectDate = (date: Dayjs) => {
    const range = selectedRange.filter(Boolean);

    if (!isRangeFlavor || !range.length || range.length === 2) {
      onSelectRange([date]);
    } else {
      onSelectRange(
        (range[0].isBefore(date) ? range.concat(date) : [date].concat(range)) as DateRange,
      );
    }

    onHoverDate(null);
  };

  return (
    <div className="Period">
      <h5>{period.format(isYearType ? "YYYY" : "MMM YYYY")}</h5>
      <div className={classnames("dates", granularity)} onMouseLeave={() => onHoverDate(null)}>
        {!isYearType ?
          Array.from({ length: 7 }, (el, i) => <h6 key={i}>{dayjs().day(i).format("ddd")}</h6>)
        : null}
        {displayDates.map((time, i) => (
          <span
            key={i}
            className={classnames({
              disabled:
                !period.isSame(time, largeInterval) ||
                (validRange[0] && dayjs(validRange[0]).isAfter(time)) ||
                (validRange[1] && dayjs(validRange[1]).isBefore(time)),
              bookend:
                // TODO: I've seen this error because day is undefined? But can tell from where...
                selectedRange.some((day) => day?.isSame(time, smallInterval)) &&
                period.isSame(time, largeInterval),
              // can't just use CSS for this for neighbor selectors because they might be on
              // different months...
              ...(isRangeFlavor ?
                {
                  "bookend-left":
                    hoveredDate ?
                      selectedRange[0]?.isSame(time, smallInterval) &&
                      hoveredDate.isAfter(time, smallInterval)
                    : selectedRange[0]?.isSame(time, smallInterval) &&
                      period.isSame(time, largeInterval),
                  "bookend-right":
                    hoveredDate ?
                      selectedRange[0]?.isSame(time, smallInterval) &&
                      hoveredDate?.isBefore(time, smallInterval)
                    : selectedRange[1]?.isSame(time, smallInterval) &&
                      period.isSame(time, largeInterval),
                }
              : {}),
              // doing this in JS means it won't get removed when we hover over the grid gaps
              hovered:
                period.isSame(time, largeInterval) && hoveredDate?.isSame(time, smallInterval),
              highlighted:
                isRangeFlavor &&
                hoveredDate &&
                period.isSame(time, largeInterval) &&
                ((hoveredDate.isBefore(selectedRange[0]) &&
                  time.isBefore(selectedRange[0]) &&
                  time.isAfter(hoveredDate)) ||
                  (hoveredDate.isAfter(selectedRange[0]) &&
                    time.isAfter(selectedRange[0]) &&
                    time.isBefore(hoveredDate))),
              selected:
                // this line just ensures we don't highlight outside a given period
                period.isSame(time, largeInterval) &&
                selectedRange[0]?.isBefore(time, smallInterval) &&
                selectedRange[1]?.isAfter(time, smallInterval),
            })}
            onClick={() => onSelectDate(time)}
            onMouseEnter={
              !isRangeFlavor || selectedRange.length === 1 ? () => onHoverDate(time) : null
            }
          >
            {isYearType ? time.format("MMM") : time.date()}
          </span>
        ))}
      </div>
    </div>
  );
}
