import type { ButtonElement, ButtonProps } from "@/components/Button";
import type { HovercardToggleHandle } from "@/components/HovercardToggle";
import type { SelectableListGroup, SelectableListItem } from "@/components/SelectableList";
import type { IconsType } from "@/components/SvgIcon";
import type { ForwardedRef } from "react";

import { forwardRef, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";

import Button from "@/components/Button";
import HovercardToggle from "@/components/HovercardToggle";
import SelectableList from "@/components/SelectableList";
import SvgIcon from "@/components/SvgIcon";

import { classnames } from "@/js/utils/cambio";

import { Alignments } from "../Hovercard";
import TextOverflow from "../TextOverflow";

type Flavors = "default" | "inline";

interface Option extends Omit<SelectableListItem, "key"> {
  value: SelectableListItem["key"];
}

export interface OptionGroup extends Omit<SelectableListGroup, "items"> {
  items: Option[];
}

interface SelectProps {
  /**
   * This should generally not be used, since dropdowns should have this set to "extents", but we
   * can allow this for an override.
   */
  alignment?: Alignments;
  /** this will skip auto-sizing of the component and it will take up full-width of its parent */
  block?: boolean;
  disabled?: boolean;
  flavor?: Flavors;
  /** An extra class name for the hovercard for use when the hoverard needs special targeting */
  hovercardClassName?: string;
  /** renders a top-level icon (each select option can also have an icon listed next to them) */
  icon?: IconsType;
  id?: string;
  /** true when failing form validation */
  invalid?: boolean;
  /** when true, the hovercard will be open initially on render */
  initiallyOpen?: boolean;
  label?: string;
  options?: Option[];
  /** Passing this will take precedence over `options` */
  optionGroups?: OptionGroup[];
  onSelect: (value: any) => void;
  placeholder?: string;
  size?: ButtonProps["size"];
  value?: string | number;
}

// gives a little visual space and also room for a possible scrollbar
const BUFFER = 12;

export interface SelectHandle {
  focus: () => void;
  open: () => void;
}

/**
 * Our in-house dropdown component. Simply renders a button that toggles a list of options in a
 * hovercard. It sizes itself in a couple different ways:
 *
 * 1. By auto-sizing based on the width of its contents. This the default behavior. This way,
 *    the button itself does not change size every time a selection is made. The width set on it
 *    is that of the largest option.
 * 2. By relying on its parent, which can just be set via CSS. This happens when the `block` prop
 *    is true. In that case, the Select component is not auto-sized and it is set to width: 100%.
 */
const Select = (
  {
    disabled,
    block,
    flavor = "default",
    icon,
    id,
    options = [],
    optionGroups = [],
    onSelect,
    invalid,
    hovercardClassName,
    value,
    placeholder,
    alignment = flavor === "inline" ? "left" : "extents",
    ...props
  }: SelectProps,
  ref: ForwardedRef<SelectHandle>,
) => {
  const [dropdownNode, setDropdownNode] = useState<HTMLDivElement>();
  const [width, setWidth] = useState<number>(null);

  const triggerRef = useRef<ButtonElement>(null);
  const hovercardRef = useRef<HovercardToggleHandle>(null);
  const isInline = flavor === "inline";

  const currentItem =
    optionGroups.length ?
      optionGroups.flatMap(({ items }) => items).find((option) => option.value === value)
    : options.find((option) => option.value === value);
  // if we have an icon in the select button (other than the chevron), we need to make sure
  // we account for it when we auto-size
  const WIDTH_BUFFER = (icon ? 24 : 0) + BUFFER;

  const getDropdownContents = (items = options, { prerender } = { prerender: false }) => {
    // when pre-rendering, make all of the options selected so that calc width with the check icon
    const optionMap = (option: Option) => ({
      ...option,
      key: option.value,
      ...(prerender || option.value === value ? { selected: true } : {}),
    });

    return (
      <SelectableList
        // only one of optionGroups or options should be passed. SelectableList will prioritize groups
        groups={optionGroups.map((optGroup) => ({
          ...optGroup,
          items: optGroup.items.map(optionMap),
        }))}
        items={options.map(optionMap)}
        onClick={(item, evt) => onSelect(item.key)}
      />
    );
  };

  /**
   * Allows a parent to toggle the list open and close and also to focus the button trigger.
   */
  useImperativeHandle(ref, () => ({
    focus: () => triggerRef.current.focus(),
    open: () => hovercardRef.current.open(),
  }));

  /**
   * Set the width of our component as the width of the invisible container. Then we can remove
   * the invisible container and render our Select component at the right size for its options.
   */
  useLayoutEffect(() => {
    if (dropdownNode) {
      setWidth(dropdownNode.offsetWidth + WIDTH_BUFFER);
    }
  }, [dropdownNode]);

  return !block && !isInline && !width && alignment === "extents" ?
      <div
        ref={(node) => setDropdownNode(node?.firstChild as HTMLDivElement)}
        className="invisible-container"
      >
        {getDropdownContents(
          // make sure when calculate width we leave enough space for a placeholder, if one exists
          options.concat(placeholder ? { value: "placeholder", display: placeholder } : []),
          { prerender: true },
        )}
      </div>
    : <span
        className={classnames("Select", { invalid, block, inline: isInline })}
        role="listbox"
        id={id}
        style={{ width }}
      >
        <HovercardToggle
          ref={hovercardRef}
          alignment={alignment}
          disabled={disabled}
          contents={getDropdownContents}
          hovercardClassName={classnames("select-hovercard", hovercardClassName)}
          position="bottom"
          initiallyOpen={props.initiallyOpen}
        >
          <Button
            ref={triggerRef}
            icon={currentItem?.icon || icon}
            label={props.label}
            size={props.size}
          >
            <TextOverflow>{currentItem?.display || placeholder}</TextOverflow>
            <SvgIcon name="chevron-down" />
          </Button>
        </HovercardToggle>
      </span>;
};

export default forwardRef(Select);
